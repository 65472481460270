const actions = {
  SET_ALL_REQUESTS: "SET_ALL_REQUESTS",
  SET_ALL_EQUIPMENT: "SET_ALL_EQUIPMENT",
  setRequests: (data) => ({
    type: actions.SET_ALL_REQUESTS,
    payload: data,
  }),
  setEquipment: (data) => ({
    type: actions.SET_ALL_EQUIPMENT,
    payload: data,
  }),
};
export default actions;

import { LOCATION_CHANGE } from "connected-react-router";
import { history } from "./store";
import actions from "./auth/actions";
import { axiosWithoutToken } from "./axiosMiddleware";
import { AnyAction, Store } from "redux";
import { fetchInitialData, get, SERVICES } from "./apis/global";
import {
  OAUTH_CLIENT_ID,
  REDIRECT_URI,
} from "../containers/Pages/SignIn/config";

const loadingMiddleware =
  (store: Store) => (next: any) => async (action: AnyAction) => {
    if (action.type === LOCATION_CHANGE) {
      const isFirstRendering = action.payload.isFirstRendering;
      const isUserLoggedIn = localStorage.getItem("isCapitalUserLoggedIn");
      console.log("isUserLoggedIn : ", isUserLoggedIn);
      if (isFirstRendering && isUserLoggedIn) {
        const currentRoute = history.location.pathname;

        try {
          history.replace("/refreshing");
          store.dispatch(actions.refreshing(true));
          const response = await axiosWithoutToken.post("/oauth/token", {
            grant_type: "refresh_token",
            client_id: OAUTH_CLIENT_ID,
            redirect_uri: REDIRECT_URI,
          });
          if (response.data.access_token) {
            localStorage.setItem("isCapitalUserLoggedIn", "true");
            store.dispatch(actions.loginSuccess(response.data.access_token));
            const user = await get(`${SERVICES.ACCOUNT}/own`);
            if (user) {
              store.dispatch(actions.getUser(user));
              fetchInitialData(store.dispatch, user);
              history.replace(currentRoute);
            } else {
              refreshCatch(store);
            }
          } else {
            refreshCatch(store);
          }
        } catch (e) {
          console.log("eeeee : ", e);
          refreshCatch(store);
        }
      }
    }
    // pass onto next middleware
    return next(action);
  };

const refreshCatch = (store: Store) => {
  history.replace("/signin");
  store.dispatch(actions.refreshing(false));
  localStorage.removeItem("isCapitalUserLoggedIn");
};

export default loadingMiddleware;

import actions from "./actions";

const initState = { planning: [], planningDetails: {}, selectedPlanning: null };

export default function planningReducer(state = initState, action) {
  switch (action.type) {
    case actions.SET_ALL_PLANNING:
      return {
        ...state,
        planning: action.payload,
      };
    case actions.SET_PLANNING_DETAILS:
      return {
        ...state,
        planningDetails: action.payload,
      };
    case actions.SET_SELECTED_PLANNING:
      return {
        ...state,
        selectedPlanning: action.payload,
      };
    default:
      return state;
  }
}

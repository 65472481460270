export const EChatActionTypes = {
  UPDATE_CHANNEL_GROUP: "UPDATE_CHANNEL_GROUP",
  UPDATE_CHANNEL_GROUP_SUCCESS: "UPDATE_CHANNEL_GROUP_SUCCESS",
  UPDATE_CHANNEL_GROUP_FAILED: "UPDATE_CHANNEL_GROUP_FAILED",
  GET_ACTIVE_CHANNELS: "GET_ACTIVE_CHANNELS",
  GET_ACTIVE_CHANNELS_SUCCESS: "GET_ACTIVE_CHANNELS_SUCCESS",
  GET_ACTIVE_CHANNELS_FAILED: "GET_ACTIVE_CHANNELS_FAILED",
  CREATE_NEW_CHANNEL: "CREATE_NEW_CHANNEL",
  CREATE_NEW_CHANNEL_SUCCESS: "CREATE_NEW_CHANNEL_SUCCESS",
  CREATE_NEW_CHANNEL_FAILED: "CREATE_NEW_CHANNEL_FAILED",
  GET_RECENT_CHANNELS: "GET_RECENT_CHANNELS",
  GET_RECENT_CHANNELS_SUCCESS: "GET_RECENT_CHANNELS_SUCCESS",
  GET_RECENT_CHANNELS_FAILED: "GET_RECENT_CHANNELS_FAILED",
  SET_ACTIVE_CHANNELS_SUCCESS: "SET_ACTIVE_CHANNELS_SUCCESS",
  SET_CURRENT_CHANNEL: "SET_CURRENT_CHANNEL",
  SET_MESSAGES_SUCCESS: "SET_MESSAGES_SUCCESS",
  SET_MESSAGES_HISTORY_SUCCESS: "SET_MESSAGES_HISTORY_SUCCESS",
  GET_NEW_MESSAGE: "GET_NEW_MESSAGE",
  GET_UNREAD_MESSAGE: "GET_UNREAD_MESSAGE",
  GET_UNREAD_MESSAGE_SUCCESS: "GET_UNREAD_MESSAGE_SUCCESS",
  GET_UNREAD_MESSAGE_FAILED: "GET_UNREAD_MESSAGE_FAILED",
  SET_READ_ALL_MESSAGES: "SET_READ_ALL_MESSAGES",
  SET_READ_ALL_MESSAGES_SUCCESS: "SET_READ_ALL_MESSAGES_SUCCESS",
  SET_READ_ALL_MESSAGES_FAILED: "SET_READ_ALL_MESSAGES_FAILED",
  SEND_NEW_MESSAGE: "SEND_NEW_MESSAGE",
  SEND_NEW_MESSAGE_SUCCESS: "SEND_NEW_MESSAGE_SUCCESS",
  SEND_NEW_MESSAGE_FAILED: "SEND_NEW_MESSAGE_FAILED",
  GET_CHANNEL_MEMBERS: "GET_CHANNEL_MEMBERS",
  GET_CHANNEL_MEMBERS_SUCCESS: "GET_CHANNEL_MEMBERS_SUCCESS",
  GET_CHANNEL_MEMBERS_FAILED: "GET_CHANNEL_MEMBERS_FAILED",
  GET_MESSAGES_HISTORY: "GET_MESSAGES_HISTORY",
  GET_MESSAGES_HISTORY_SUCCESS: "GET_MESSAGES_HISTORY_SUCCESS",
  GET_MESSAGES_HISTORY_FAILED: "GET_MESSAGES_HISTORY_FAILED",
  SET_NOTIFICATION_MESSAGE: "SET_NOTIFICATION_MESSAGE",
  UPLOAD_IMAGE_HISTORY: "UPLOAD_IMAGE_HISTORY",
  UPLOAD_IMAGE_HISTORY_SUCCESS: "UPLOAD_IMAGE_HISTORY_SUCCESS",
  UPLOAD_IMAGE_HISTORY_FAILED: "UPLOAD_IMAGE_HISTORY_FAILED",
  ADD_IMAGE_REQUEST_HISTORY: "ADD_IMAGE_REQUEST_HISTORY",
  ADD_IMAGE_REQUEST_HISTORY_SUCCESS: "ADD_IMAGE_REQUEST_HISTORY_SUCCESS",
  ADD_IMAGE_REQUEST_HISTORY_FAILED: "ADD_IMAGE_REQUEST_HISTORY_FAILED",
  SET_APP_STATE: "SET_APP_STATE",
  GET_NEW_CHANNEL: "GET_NEW_CHANNEL",
  SEARCH_RECENT_CHANNELS: "SEARCH_RECENT_CHANNELS",
  SEARCH_RECENT_CHANNELS_SUCCESS: "SEARCH_RECENT_CHANNELS_SUCCESS",
  SEARCH_RECENT_CHANNELS_FAILED: "SEARCH_RECENT_CHANNELS_FAILED",
  SET_CHANNEL_DETAILS: "SET_CHANNEL_DETAILS",
  SET_CHANNEL_DETAILS_SUCCESS: "SET_CHANNEL_DETAILS_SUCCESS",
  SET_CHANNEL_DETAILS_FAILED: "SET_CHANNEL_DETAILS_FAILED",
  GET_UPDATED_CHANNEL: "GET_UPDATED_CHANNEL",
  READ_MESSAGE_BY_ID: "READ_MESSAGE_BY_ID",
  READ_MESSAGE_BY_ID_SUCCESS: "READ_MESSAGE_BY_ID_SUCCESS",
  READ_MESSAGE_BY_ID_FAILED: "READ_MESSAGE_BY_ID_FAILED",
  TOGGLE_CHAT_WIDGET: "TOGGLE_CHAT_WIDGET",
  TOGGLE_WINDOW_ACTIVE: "TOGGLE_WINDOW_ACTIVE",
  ADD_NEW_CHANNEL_MEMBERS: "ADD_NEW_CHANNEL_MEMBERS",
  ADD_NEW_CHANNEL_MEMBERS_SUCCESS: "ADD_NEW_CHANNEL_MEMBERS_SUCCESS",
  ADD_NEW_CHANNEL_MEMBERS_FAILED: "ADD_NEW_CHANNEL_MEMBERS_FAILED",
};

export const chatActions = {
  createNewChannel: (payload) => ({
    type: EChatActionTypes.CREATE_NEW_CHANNEL,
    payload,
  }),
  getActiveChannels: (payload) => ({
    type: EChatActionTypes.GET_ACTIVE_CHANNELS,
    payload,
  }),
  getRecentChannels: (payload) => ({
    type: EChatActionTypes.GET_RECENT_CHANNELS,
    payload,
  }),
  setCurrentChannel: (payload) => ({
    type: EChatActionTypes.SET_CURRENT_CHANNEL,
    payload,
  }),
  getChannelMembers: (payload) => ({
    type: EChatActionTypes.GET_CHANNEL_MEMBERS,
    payload,
  }),
  setMessagesSuccess: (messages, channelId) => ({
    type: EChatActionTypes.SET_MESSAGES_SUCCESS,
    payload: { messages, channelId },
  }),
  getNewMessage: (message) => ({
    type: EChatActionTypes.GET_NEW_MESSAGE,
    payload: message,
  }),
  getUnreadMessage: () => ({
    type: EChatActionTypes.GET_UNREAD_MESSAGE,
  }),
  setReadAllMessages: (payload) => ({
    type: EChatActionTypes.SET_READ_ALL_MESSAGES,
    payload,
  }),
  readMessageById: (payload) => ({
    type: EChatActionTypes.READ_MESSAGE_BY_ID,
    payload,
  }),
  sendNewMessage: (payload) => ({
    type: EChatActionTypes.SEND_NEW_MESSAGE,
    payload,
  }),
  getMessagesHistory: (payload) => ({
    type: EChatActionTypes.GET_MESSAGES_HISTORY,
    payload,
  }),
  setNotificationMessage: (payload) => ({
    type: EChatActionTypes.SET_NOTIFICATION_MESSAGE,
    payload,
  }),
  uploadImageHistory: (payload) => ({
    type: EChatActionTypes.UPLOAD_IMAGE_HISTORY,
    payload,
  }),
  addImageRequest: (payload) => ({
    type: EChatActionTypes.ADD_IMAGE_REQUEST_HISTORY,
    payload,
  }),
  updateChannelGroup: (payload) => ({
    type: EChatActionTypes.UPDATE_CHANNEL_GROUP,
    payload,
  }),
  setAppState: (payload) => ({
    type: EChatActionTypes.SET_APP_STATE,
    payload,
  }),
  getNewChannel: (payload) => ({
    type: EChatActionTypes.GET_NEW_CHANNEL,
    payload,
  }),
  searchRecentChannels: (payload) => ({
    type: EChatActionTypes.SEARCH_RECENT_CHANNELS,
    payload,
  }),
  setChannelDetails: (payload) => ({
    type: EChatActionTypes.SET_CHANNEL_DETAILS,
    payload,
  }),
  getUpdatedChannel: (payload) => ({
    type: EChatActionTypes.GET_UPDATED_CHANNEL,
    payload,
  }),
  toggleChatWidget: (payload) => ({
    type: EChatActionTypes.TOGGLE_CHAT_WIDGET,
    payload,
  }),
  toggleWindowActive: (payload) => ({
    type: EChatActionTypes.TOGGLE_WINDOW_ACTIVE,
    payload,
  }),
  addNewChannelMembers: (payload) => ({
    type: EChatActionTypes.ADD_NEW_CHANNEL_MEMBERS,
    payload,
  }),
};

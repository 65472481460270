import actions from "./actions";

const initState = { agents: [], licenses: [] };

export default function agentsReducer(state = initState, action) {
  switch (action.type) {
    case actions.SET_ALL_AGENTS:
      return {
        ...state,
        agents: action.payload,
      };
    case actions.SET_ALL_LICENSES:
      return {
        ...state,
        licenses: action.payload,
      };
    default:
      return state;
  }
}

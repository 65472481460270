import React from "react";

import { Space, Typography } from "antd";
import { TableViews } from "../../Tables/AntTables/AntTables";
import { EditFilled, DeleteFilled } from "@ant-design/icons";
import { Agent, Shift } from "../../../redux/apis/types";
import { destroy, SERVICES } from "../../../redux/apis/global";
import { useDispatch, useSelector } from "react-redux";
import { State } from "../../../redux/root-reducer";
import actions from "../../../redux/planning/actions";
import Modals from "../../../components/Feedback/Modal";
import { timeZoneMoment } from "../../../redux/apis/utils";
import moment from "moment";
import { isAdmin, isSubContractor } from "../../../constants";

type Props = {
  shifts: Array<Shift>;
  onEditShift: (shift: Shift) => void;
};

const ShiftList = ({ shifts, onEditShift }: Props) => {
  console.log("isAdmin : ", isAdmin);
  const { planningDetails } = useSelector((state: State) => state.Planning);
  const dispatch = useDispatch();
  const columns = [
    {
      title: "Start",
      render: (text: string, record: Shift) => (
        <Typography.Text>
          {timeZoneMoment(
            record.start,
            planningDetails.location?.timezone
          ).format("DD-MM-YYYY HH:mm")}
        </Typography.Text>
      ),
    },
    {
      title: "End",
      render: (text: string, record: Shift) => (
        <Typography.Text>
          {timeZoneMoment(
            record.end,
            planningDetails.location?.timezone
          ).format("DD-MM-YYYY HH:mm")}
        </Typography.Text>
      ),
    },
    {
      title: "Agents",
      width: "30%",
      render: (text: string, record: Shift) => (
        <Typography.Text>
          {record.agents
            ? record.agents
                .map(
                  (agent: Agent) => `${agent.first_name[0]} ${agent.last_name}`
                )
                .join(", ")
            : "-"}
        </Typography.Text>
      ),
    },
    {
      title: "Subcontractor",
      render: (text: string, record: any) => (
        <Typography.Text>
          {record.contractor ? record.contractor.name : "-"}
        </Typography.Text>
      ),
    },
    {
      title: "Actions",
      fixed: "right",
      render: (text: string, record: any) => (
        <Space size="middle">
          <EditFilled
            style={{ color: "#242b50" }}
            onClick={() => onEditShift(record)}
          />
          {(isSubContractor && planningDetails?.subcontractor_id) || isAdmin ? (
            <DeleteFilled
              style={{ color: "red" }}
              onClick={() => onDeleteShift(record.id)}
            />
          ) : (
            <div />
          )}
        </Space>
      ),
    },
  ];

  const onDeleteShift = async (id: number) => {
    Modals.confirm({
      title: "Are you sure you want to delete this shift ?",
      content: "All agents will be unassigned from this shift",
      async onOk() {
        await destroy(SERVICES.SHIFT, id);

        const copy = Object.assign({}, planningDetails);
        copy.shifts = copy.shifts.filter((item) => item.id !== id);
        dispatch(actions.updatePlanning(copy));
      },
      onCancel() {},
      okText: "Confirm",
      cancelText: "Cancel",
    });
  };

  return <TableViews.SimpleView dataSource={shifts} columns={columns} />;
};

export default ShiftList;

import moment from "moment";
import React from "react";
import { SERVICES } from "../../../redux/apis/global";
import { PlanningHistory } from "../../../redux/apis/types";
import { BASE_PATH } from "../../../redux/axiosMiddleware";
import RemoteImage from "./RemoteImage";
import { variables } from "../../../assets/styles/variables";
import { HISTORY_STATUS_COLOR, stopDuration } from "../../../redux/apis/utils";

type Props = {
  history: PlanningHistory;
};

const HistoryItem = ({ history }: Props) => {
  return (
    <div>
      <div>
        <div
          style={{
            backgroundColor:
              HISTORY_STATUS_COLOR[history.status] || variables.PRIMARY_COLOR,
            borderRadius: 5,
            paddingLeft: 5,
            paddingRight: 5,
            color: "white",
            width: "fit-content",
          }}
        >
          {history.status.toUpperCase()}
          {" : "}
          {history.agent_shift_id}
        </div>
        <div style={{ fontWeight: "bold", fontStyle: "italic" }}>
          {moment(history.created_at).format("YYYY/MM/DD HH:mm")}
        </div>

        {history.status === "break" && (
          <div style={{ fontWeight: "bold", fontStyle: "italic" }}>
            Duration:{" "}
            {history.break_finished_at
              ? stopDuration(
                  history.created_at,
                  moment(history.break_finished_at)
                )
              : "In progress"}
          </div>
        )}

        {history.note && (
          <div style={{ fontSize: 15 }}>
            <q>{history.note}</q>{" "}
          </div>
        )}
        {history.break_reason && (
          <div style={{ fontSize: 15 }}>
            <q>Reason: {history.break_reason}</q>{" "}
          </div>
        )}
        {history.image && (
          <RemoteImage
            url={`${BASE_PATH}/api/${SERVICES.PLANNING_HISTORY}/image/${history.request_id}/${history.image}`}
          />
        )}
      </div>
    </div>
  );
};

export default HistoryItem;

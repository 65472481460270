import { Col, Row } from "antd";
import React from "react";
import DatePickerController from "../../../form/DatePickerController";
import InputController from "../../../form/InputController";

const DateTimeRangeController = () => {
  return (
    <>
      <Row gutter={[10, 10]}>
        <Col md={12} sm={24} xs={24}>
          <DatePickerController
            rules={{ required: true }}
            name={`start_date`}
            label="Start date"
          />
        </Col>
        <Col md={12} sm={24} xs={24}>
          <InputController
            name={`start_time`}
            label="Start time"
            rules={{ required: true }}
            type="time"
            placeholder="start"
          />
        </Col>
      </Row>
      <Row gutter={[10, 10]}>
        <Col md={12} sm={24} xs={24}>
          <DatePickerController name={`end_date`} label="End date" />
        </Col>
        <Col md={12} sm={24} xs={24}>
          <InputController
            name={`end_time`}
            label="End time"
            rules={{ required: true }}
            type="time"
            placeholder="end"
          />
        </Col>
      </Row>
    </>
  );
};

export default DateTimeRangeController;

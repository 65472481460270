const actions = {
  SET_ALL_AGENTS: "SET_ALL_AGENTS",
  SET_ALL_LICENSES: "SET_ALL_LICENSES",
  setAgents: (data) => ({
    type: actions.SET_ALL_AGENTS,
    payload: data,
  }),
  setLicenses: (data) => ({
    type: actions.SET_ALL_LICENSES,
    payload: data,
  }),
};
export default actions;

import { Moment } from "moment";

export type Client = {
  id: number;
  name: string;
  logo?: string;
};

export type Branch = {
  id: number;
  name: string;
  client_id: number;
  billing_address: string;
  company_registration_number: string;
  vat_number: string;
  country: string;
};

export type License = {
  id: number;
  name: string;
};
export type Location = {
  id: number;
  name: string;
  longitude: string;
  latitude: string;
  address: string;
  country: string;
  city: string;
  client_id: string;
  branch_id: string;
  timezone: string;
  number: string;
};

export type UserAccount = User;

export type Contact = {
  branch_id: number | null;
  client_id: number | null;
  created_at: string;
  email: string;
  emergency: 0 | 1;
  emergency_bcc: 0 | 1;
  emergency_cc: 0 | 1;
  finance: 0 | 1;
  finance_bcc: 0 | 1;
  finance_cc: 0 | 1;
  id: number;
  name: string;
  note?: string;
  phone_number?: string;
  request_cc: 0 | 1;
  subcontractor_id: string | null;
  updated_at: string;
};
export type Contractor = {
  id: number;
  name: string;
  contact_person: string;
  email: string;
  phone_number: string;
  emergency_phone_number: string;
  address: string;
  vat_number: string;
  company_registration_number: string;
  status: "confirmed" | "deleted";
  created_at: string;
  updated_at: string;
};

export type Notification = {
  data: any[];
};
export type Request = {
  id: number;
  identifier: string;
  po_number: string;
  status: RequestStatus;
  client_id: number;
  branch_id: number;
  location_id: number;
  note: string;
  equipment: Array<Equipment>;
  created_at: string;
  updated_at: string;
  branch: Branch;
  client: Client;
  location: Location;
  request_timeslots: Array<RequestTimeSlot>;
  equipment_quantity?: Array<Equipment>;
};

export type Planning = {
  id: number;
  request_id: number;
  request_timeslot_id: number;
  start: string;
  end: string;
  client_id: number;
  branch_id: number;
  subcontractor_id: number | null;
  location_id: number;
  status: string;
  contractor_status: string | null;
  request_type: RequestType;
  number_of_agents: number;
  note: string | null;
  created_at: string;
  updated_at: string;
  shifts: Array<Shift>;
  contractor?: Contractor | null;
  request: Request;
  location?: Location;
  branch?: Branch;
  client: Client;
  has_shifts: boolean;
  history: PlanningHistory[];
};

export type SubContractor = {
  id: number;
  name: string;
};
export type Agent = {
  id: number;
  first_name: string;
  last_name: string;
  licenses: Array<string>;
  subcontractor_id: number | null;
  user: User;
  contractor: Contractor;
  valid_from: string;
  valid_until: string;
  license_number: string;
  blocked: 1 | 0;
};

export type User = {
  agent_id: number | null;
  app_version: string;
  branch_id: number | null;
  client_id: number | null;
  subcontractor_id: number | null;
  created_at: string;
  deleted_at: null | string;
  email: string;
  email_verified_at: string | null;
  entity: "agent" | "admin" | "branch" | "client" | "contractor";
  first_name: string;
  id: number;
  last_name: string;
  phone_model: string | null;
  phone_number: string;
  phone_platform: string | null;
  phone_version: string | null;
  roles: Array<"agent" | "admin" | "branch" | "client" | "contractor">;
  status: number;
  updated_at: string;
  verified: 1 | 0;
  blocked: 1 | 0;
  branch?: Branch;
  contractor?: Contractor;
};
export type Shift = {
  id: number;
  start: string;
  end: string;
  subcontractor_id: number;
  planning_id: number;
  agents: Array<Agent & { pivot: AgentShift }>;
  contractor?: Contractor;
  status: string;
};

export type AgentShift = {
  agent_id: number;
  id: number;
  latitude: number | null;
  longitude: number | null;
  shift_id: number;
  sos: number | null;
  status: AgentShiftStatus;
};

export enum AgentShiftStatus {
  pending = "pending",
  started = "started",
  ended = "ended",
  confirmed = "confirmed",
}
export type Equipment = {
  id: number;
  name: string;
  quantity?: number;
};

export type Timeslot = {
  start_time: string;
  end_time: string;
  week_days: Array<WeekDays>;
  number_of_agents: number;
};

export type RequestAction = {
  id: number;
  action: "ACCEPT" | "REJECT";
  reason: string;
};
export type RequestTimeSlot = {
  id: number;
  request_id: number;
  start: string;
  end: string;
  date_range?: Array<Moment>;
  request_type: RequestType;
  continuous: 0 | 1;
  timeslots: Array<Timeslot>;
  status: RequestStatus;
  reason: string;
  note: string;
  created_at: string;
  updated_at: string;
};

export type UserEntity = {
  ADMIN: "admin";
  CLIENT: "client";
  AGENT: "agent";
  CONTRACTOR: "contractor";
};

export enum RequestStatus {
  pending = "pending",
  confirmed = "confirmed",
  cancelled = "cancelled",
}

export type ActionStatus = {
  pending: "pending";
  confirmed: "confirmed";
  finished: "finished";
  rejected: "rejected";
  cancelled: "cancelled";
  archived: "archived";
};

export type RequestType = {
  STATIC: "Static";
};

export enum WeekDays {
  MONDAY = "Monday",
  TUESDAY = "Tuesday",
  WEDNESDAY = "Wednesday",
  THURSDAY = "Thursday",
  FRIDAY = "Friday",
  SATURDAY = "Saturday",
  SUNDAY = "Sunday",
}

export type PlanningHistory = {
  id: number;
  agent_id: number;
  agent: Agent;
  agent_shift_id: number;
  created_at: string;
  note: string;
  planning_id: number;
  request_id: number;
  shift_id: number;
  status: "started" | "ended" | "image" | "break" | "cost" | "note";
  updated_at: string;
  break_finished_at: string;
  break_reason: string;
  cost: number;
  currency: string;
  image: string;
  latitude: number;
  longitude: number;
  tr_note: string;
  tr_reason: string;
};

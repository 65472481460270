import React from "react";
import {
  Controller,
  FieldError,
  RegisterOptions,
  useFormContext,
} from "react-hook-form";
import Select, { SelectOption } from "../components/uielements/select";
import ErrorInputMessage from "./ErrorInputMessage";
import { CloseCircleFilled } from "@ant-design/icons";

type Props = {
  name: string;
  label?: string;
  rules?: RegisterOptions;
  placeholder?: string;
  error?: FieldError;
  defaultValue?: any[];
  options: any[];
  optionKey?: string;
  optionValue?: string;
  optionValue2?: string;
  style?: any;
  mode?: string;
  notFoundContent?: any;
  format?: (value: string) => string;
  onSearch?: (value: string) => void;
  searchValue?: string;
  valueType?: "json" | "string";
  separator?: string;
};
const SelectController = ({
  name,
  label,
  rules,
  placeholder,
  error,
  defaultValue = [],
  options,
  optionKey,
  optionValue,
  optionValue2,
  separator,
  format,
  style,
  mode,
  valueType = "string",
  ...rest
}: Props) => {
  const {
    control,
    formState: { errors },
    setValue,
  } = useFormContext();

  const fieldError = error ? error : errors && errors[name];

  return (
    <div style={{ width: "100%" }}>
      {label && <h4>{label}</h4>}
      <Controller
        rules={rules}
        control={control}
        name={name}
        render={({ field }) => (
          <div style={{ display: "flex", position: "relative" }}>
            <Select
              {...field}
              style={{ width: "100%", ...style }}
              mode={mode}
              showSearch
              optionFilterProp="children"
              filterOption={(input: any, option: any) =>
                (option!.children as unknown as string)
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
              placeholder={placeholder ? placeholder : label?.toLowerCase()}
              defaultValue={defaultValue}
              {...rest}
              // dropdownRender={(menu: any) =>
              //   <div>
              //     {children}
              //     {menu}
              //   </div>
              // )}
            >
              {options.map((option, index) => (
                <SelectOption
                  key={index}
                  value={
                    typeof option === "string"
                      ? option
                      : valueType === "json"
                      ? JSON.stringify(option)
                      : option[optionKey || name]
                  }
                >
                  {typeof option === "string"
                    ? option
                    : `${
                        format
                          ? format(option[optionValue || name])
                          : option[optionValue || name]
                      }${separator || " "}${
                        optionValue2 && option[optionValue2]
                          ? format
                            ? format(option[optionValue2])
                            : option[optionValue2]
                          : ""
                      }`}
                </SelectOption>
              ))}
            </Select>
            {field.value && (
              <CloseCircleFilled
                onClick={() => setValue(name, null)}
                style={{
                  marginTop: "auto",
                  marginBottom: "auto",
                  marginLeft: 5,
                }}
              />
            )}
          </div>
        )}
      />
      <ErrorInputMessage error={fieldError} />
    </div>
  );
};

export default SelectController;

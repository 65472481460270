import React, { useEffect, useState } from "react";

import Modal from "../../../components/Feedback/Modal";
import { CalendarModalBody } from "../NewPlanning/Calendar.styles";
import { Col, Row } from "antd";
import { FormProvider, useForm } from "react-hook-form";
import Modals from "../../../components/Feedback/Modal";
import TextAreaController from "../../../form/TextAreaController";
import InputController from "../../../form/InputController";
import Card from "../../UIElements/Card/Card.styles";
import DateTimeRangeController from "../Planning/DateTimeRangeController";
import Button from "../../../components/uielements/button";
import History from "./History";
import ShiftList from "./ShiftList";
import NewShiftModal from "./NewShiftModal";
import ConfirmPlanningModal from "./ConfirmPlanningModal";
import ForwardPlanningModal from "./ForwardPlanningModal";
import { destroy, get, SERVICES } from "../../../redux/apis/global";
import { useDispatch, useSelector } from "react-redux";
import actions from "../../../redux/planning/actions";
import { State } from "../../../redux/root-reducer";
import { RequestStatus, Shift } from "../../../redux/apis/types";
import { defaultFormPlanningDetails } from "../../../redux/apis/utils";
import { cancelPlanning, forwardPlanning } from "../../../redux/apis/planning";
import { isAdmin, isSubContractor } from "../../../constants";
import NewHistoryModal from "./NewHistoryModal";

type Props = {
  modalVisible: boolean;
  onClose: () => void;
  planningId: number;
};
export default function ({ modalVisible, onClose, planningId = 1 }: Props) {
  const [historyModalVisible, setHistoryModalVisible] = useState(false);
  const [editShift, setEditShift] = useState<Shift | undefined>();
  const dispatch = useDispatch();
  const { planningDetails, planning } = useSelector(
    (state: State) => state.Planning
  );
  const [newShiftVisible, setNewShiftVisible] = useState(false);
  const [confirmPlanningVisible, setConfirmPlanningVisible] = useState(false);
  const [forwardPlanningVisible, setForwardPlanningVisible] = useState(false);
  const methods = useForm();
  const { handleSubmit, reset, getValues, formState } = methods;

  useEffect(() => {
    async function getData() {
      const data = await get(SERVICES.PLANNING, planningId);
      dispatch(actions.setPlanningDetails(data));
    }

    getData();
  }, []);

  useEffect(() => {
    if (planningDetails && planningDetails.request) {
      reset({
        ...getValues(),
        ...defaultFormPlanningDetails(planningDetails),
      });
    }
  }, [planningDetails]);

  const onSave = (data: any) => {
    console.log(data);
  };

  const visible = modalVisible ? true : false;
  if (!visible) return null;

  const onDeletePlanning = () => {
    Modals.confirm({
      title: "Are you sure you want to delete this planning ?",
      content: "All shifts will be removed from this planning",
      async onOk() {
        const resp = await destroy(SERVICES.PLANNING, null, {
          planning_id: [planningDetails.id],
        });
        if (resp) {
          dispatch(
            actions.setPlanning(
              planning.filter((item) => item.id !== planningDetails.id)
            )
          );
          dispatch(actions.updatePlanning(null, planningDetails.id));
          onClose();
        }
      },
      onCancel() {},
      okText: "Confirm",
      cancelText: "Cancel",
    });
  };

  const onCancelPlanning = () => {
    Modals.confirm({
      title: "Are you sure you want to cancel this planning ?",
      content: "an email will be sent to the client.",
      async onOk() {
        const resp = await cancelPlanning(planningDetails.id);
        if (resp) {
          dispatch(
            actions.updatePlanning(
              {
                ...planningDetails,
                status: RequestStatus.cancelled,
              },
              planningDetails.id
            )
          );
          onClose();
        }
      },
      onCancel() {},
      okText: "Confirm",
      cancelText: "Cancel",
    });
  };
  const onCancelForward = () => {
    Modals.confirm({
      title: "Are you sure you want to cancel forward this planning ?",
      content: "All shifts will be removed from this planning",
      async onOk() {
        const resp = await forwardPlanning({
          forwarded: [
            {
              subcontractor_id: null,
              planning_id: planningDetails.id,
            },
          ],
          remove_shifts: true,
        });
        if (resp) {
          const planning = await get(SERVICES.PLANNING, planningDetails.id);
          if (planning) dispatch(actions.updatePlanning(planning));
        }
      },
      onCancel() {},
      okText: "Confirm",
      cancelText: "Cancel",
    });
  };

  const onEditShift = (shift: Shift) => {
    setEditShift(shift);
    setNewShiftVisible(true);
  };

  const onCloseShift = () => {
    setNewShiftVisible(false);
    setEditShift(undefined);
  };

  console.log("isAdmin : ", isAdmin);
  return (
    <div>
      <Modal
        title={"Planning Details"}
        visible={visible}
        onOk={handleSubmit(onSave)}
        onCancel={onClose}
        okText="Save"
        cancelText="Cancel"
        width="100%"
        style={{ top: 20 }}
      >
        <CalendarModalBody>
          {isAdmin && (
            <section>
              <Row
                gutter={[10, 10]}
                style={{ display: "flex", justifyContent: "space-between" }}
              >
                <Col>
                  <div style={{ display: "flex" }}>
                    <Button
                      onClick={() => setForwardPlanningVisible(true)}
                      type="primary"
                      style={{ marginLeft: 10 }}
                    >
                      Forward planning
                    </Button>
                    {!!planningDetails?.subcontractor_id && (
                      <Button
                        onClick={onCancelForward}
                        type="danger"
                        style={{ marginLeft: 10 }}
                      >
                        Cancel forward
                      </Button>
                    )}
                  </div>
                  {planningDetails?.subcontractor_id && (
                    <div style={{ fontWeight: "bold" }}>
                      {`Forwarded to ${planningDetails?.contractor?.name}[${planningDetails?.contractor_status}]`}
                    </div>
                  )}
                </Col>

                <Col>
                  <Button
                    onClick={onCancelPlanning}
                    type="warning"
                    style={{ marginRight: 10 }}
                  >
                    Cancel planning
                  </Button>
                  <Button onClick={onDeletePlanning} type="danger">
                    Delete planning
                  </Button>
                </Col>
              </Row>
            </section>
          )}

          <Row gutter={40}>
            <Col md={7} sm={24} xs={24}>
              <Card
                title={`Planning info [${planningDetails?.request_type}]`}
                extra={`#${planningDetails?.request?.identifier}-${planningDetails?.id}`}
              >
                <FormProvider {...methods}>
                  <form>
                    <section>
                      <Row>
                        <div>
                          <b>Branch: </b>
                          {`${planningDetails?.client?.name} -> ${planningDetails?.branch?.name}`}
                        </div>
                      </Row>
                      <Row>
                        {planningDetails?.location ? (
                          <div>
                            <b>{planningDetails.location?.name}</b>
                            <div>
                              {`${planningDetails.location?.address} ${
                                planningDetails.location?.number
                                  ? `(${planningDetails.location?.number})`
                                  : ""
                              }`}
                            </div>
                          </div>
                        ) : (
                          "Location deleted"
                        )}
                      </Row>
                    </section>
                    <section>
                      <DateTimeRangeController />
                    </section>
                    <section>
                      <h4>Description</h4>
                      <TextAreaController
                        name="description"
                        placeholder="type your instructions here (optional)"
                      />
                    </section>
                    <Button disabled={!formState.isDirty} type="primary">
                      Save
                    </Button>
                    <h3 style={{ marginTop: 20, marginBottom: 10 }}>
                      Request Information :{" "}
                    </h3>
                    <section>
                      <InputController
                        name="po_number"
                        label="PO number (optional)"
                        placeholder="PO number"
                      />
                    </section>
                    {/* <section>
                      <EquipmentSelection hideHeader={true} />
                    </section> */}
                    <Button disabled={!formState.isDirty} type="primary">
                      Save
                    </Button>
                  </form>
                </FormProvider>
              </Card>
            </Col>
            <Col md={11} sm={24} xs={24}>
              <Card
                title="Shift(s)"
                extra={
                  isAdmin === true ||
                  (isSubContractor && planningDetails?.subcontractor_id) ? (
                    <Button
                      onClick={() => setNewShiftVisible(true)}
                      type="primary"
                    >
                      Add new shift
                    </Button>
                  ) : (
                    <div />
                  )
                }
              >
                <div
                  style={{ color: "#242b50", fontWeight: "bold" }}
                >{`Number of agents : ${planningDetails?.number_of_agents}`}</div>

                <ShiftList
                  onEditShift={onEditShift}
                  shifts={planningDetails?.shifts || []}
                />
              </Card>
            </Col>
            <Col md={6} sm={24} xs={24}>
              <Card
                title="History"
                extra={
                  planningDetails?.shifts &&
                  planningDetails?.shifts?.length > 0 && (
                    <Button
                      onClick={() => setHistoryModalVisible(true)}
                      size="small"
                      type="primary"
                    >
                      Add history
                    </Button>
                  )
                }
                // extra={
                //   <FormProvider {...methods}>
                //     <SelectController
                //       name={`name`}
                //       label={undefined}
                //       rules={{ required: true }}
                //       options={["Status", "Stops", "Images"]}
                //       placeholder="options"
                //     />
                //   </FormProvider>
                // }
              >
                <History />
              </Card>
            </Col>
          </Row>
          {newShiftVisible && (
            <NewShiftModal
              visible={newShiftVisible}
              onClose={onCloseShift}
              shift={editShift}
            />
          )}
          {confirmPlanningVisible && (
            <ConfirmPlanningModal
              visible={confirmPlanningVisible}
              onClose={() => setConfirmPlanningVisible(false)}
            />
          )}
          {forwardPlanningVisible && (
            <ForwardPlanningModal
              visible={forwardPlanningVisible}
              onClose={() => setForwardPlanningVisible(false)}
            />
          )}

          {historyModalVisible && (
            <NewHistoryModal
              visible={historyModalVisible}
              onClose={() => setHistoryModalVisible(false)}
            />
          )}
        </CalendarModalBody>
      </Modal>
    </div>
  );
}

import actions from "./actions";

const initState = { contractors: [], accounts: [] };

export default function contractorReducer(state = initState, action) {
  switch (action.type) {
    case actions.SET_ALL_CONTRACTORS:
      return {
        ...state,
        contractors: action.payload,
      };
    case actions.SET_ALL_CONTRACTOR_ACCOUNTS:
      return {
        ...state,
        accounts: action.payload,
      };
    default:
      return state;
  }
}

import actions from "./actions";

const initState = { requests: [], equipment: [], notification: {} };

export default function requestReducer(state = initState, action) {
  switch (action.type) {
    case actions.SET_ALL_REQUESTS:
      return {
        ...state,
        requests: action.payload,
      };
    case actions.SET_ALL_EQUIPMENT:
      return {
        ...state,
        equipment: action.payload,
      };
    case actions.SET_NOTIFICATION:
      return {
        ...state,
        notification: action.payload,
      };
    default:
      return state;
  }
}

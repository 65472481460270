import React from "react";
import { useSelector } from "react-redux";
import {
  AppBar,
  IconButton,
  List,
  ListItem,
  TextField,
  Toolbar,
  Typography,
} from "@material-ui/core";
import Button from "../../../components/uielements/button";

import { ChevronLeft } from "@material-ui/icons";
import { variables } from "../../../assets/styles/variables";

export const getRole = (member, user) => {
  if (user.id == member.id) return "You";
  if (member.agent_id) return "Agent";
  if (member.roles[0] == "ROLE_ADMIN") return "Protectrans";
  if (member.roles[0] == "ROLE_SUBCONTRACTOR") return "Sub C";
};

const GroupMembers = ({ onClose, requests, onCreate }) => {
  const [name, setName] = React.useState("");
  const { requestsMembers } = useSelector((state) => state.requests);
  const { user } = useSelector((state) => state.Auth);
  const { loading } = useSelector((state) => state.chat);

  React.useEffect(() => {
    setName(
      [
        ...new Set(
          requests
            .filter((x) => !!x.channels)
            .map((x) =>
              x.channels.requests.map((y) => "#" + y.request.identifier)
            )
            .flat()
        ),
      ].join(" - ")
    );
  }, [requests]);

  return (
    <div
      style={{
        position: "absolute",
        left: 0,
        right: 0,
        top: 0,
        bottom: 0,
        backgroundColor: "#fff",
        zIndex: 99999999999999,
      }}
    >
      <AppBar position="static">
        <Toolbar>
          <IconButton
            edge="start"
            onClick={onClose}
            // className={classes.menuButton}
            color="inherit"
            aria-label="menu"
          >
            <ChevronLeft />
          </IconButton>
          <Typography variant="h6" style={{ flexGrow: 1 }}>
            Combined Group
          </Typography>
        </Toolbar>
      </AppBar>
      <div className="chat-frame" style={{ margin: 20 }}>
        <TextField
          fullWidth
          onChange={(e) => setName(e.target.value)}
          value={name}
          id="filled-basic"
          label="Group name"
          // variant="filled"
        />
        <List>
          {requestsMembers
            .map((x) => (x.member ? x.member.user : x))
            .map((item) => (
              <ListItem divider key={item.id}>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    justifyContent: "space-between",
                  }}
                >
                  <div>
                    <Typography style={{ fontSize: 16, color: "#333" }}>
                      {item.first_name} {item.last_name}
                    </Typography>
                    <Typography
                      style={{
                        fontSize: 14,
                        color: variables.PRIMARY_COLOR,
                        top: 5,
                      }}
                    >
                      {item.subcontractor
                        ? item.subcontractor.company_name
                        : ""}
                    </Typography>
                  </div>
                  <div style={{ position: "absolute", right: 0, top: 10 }}>
                    <Typography
                      style={{
                        color:
                          user.id == item.id ? variables.PRIMARY_COLOR : "grey",
                      }}
                    >
                      {getRole(item, user)}
                    </Typography>
                  </div>
                </div>
              </ListItem>
            ))}
        </List>
        <Button
          disabled={loading}
          style={{ container: { height: 50, marginBottom: 20 } }}
          onClick={() =>
            onCreate(
              name,
              requestsMembers.map((x) => (x.member ? x.member.user.id : x.id))
            )
          }
          color="primary"
          raised
          fullWidth
        >
          Create group
        </Button>
      </div>
    </div>
  );
};

export default GroupMembers;

import React, { useState } from "react";
import { axiosWithToken } from "../../../redux/axiosMiddleware";

type ImageWithAuthProps = {
  url: string;
};

const RemoteImage = (props: ImageWithAuthProps) => {
  const [image, setImage] = useState("");

  // Load data
  React.useEffect(() => {
    axiosWithToken
      .get(props.url, {
        responseType: "arraybuffer",
        headers: {
          Accept: "*/*",
        },
      })
      .then(async (res: any) => {
        const blob = new Blob([res.data], {
          type: "image/jpeg",
        });

        const objectURL = URL.createObjectURL(blob);
        console.log("objectURL : ", objectURL);
        setImage(objectURL);
      });
  }, []);

  return (
    <img
      width={"100%"}
      style={{ objectFit: "contain" }}
      height={200}
      src={image}
      alt={"Loading..."}
    />
  );
};

export default RemoteImage;

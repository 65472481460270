import { axiosWithToken } from "../axiosMiddleware";
import { SERVICES } from "./global";

export const getRelatedPlanning = async (id: number) => {
  try {
    const response = await axiosWithToken.get(`/api/planning/${id}/related`);
    return response?.data;
  } catch (e) {
    return null;
  }
};
export const forwardPlanning = async (data: any) => {
  try {
    const response = await axiosWithToken.post(`/api/planning/forward`, data);
    return response?.data;
  } catch (e) {
    return null;
  }
};

export const cancelPlanning = async (id: any) => {
  try {
    const response = await axiosWithToken.put(`/api/planning/${id}/cancel`);
    return response?.data;
  } catch (e) {
    return null;
  }
};

export const uploadImageHistory = async (
  id: number,
  image: any,
  agentId: any
) => {
  try {
    const form = new FormData();
    form.append("image", image);
    form.append("agent_id", agentId);
    // console.log('image : ', image);
    console.log("form : ", `/api/${SERVICES.PLANNING_HISTORY}/image/${id}`);
    const response = await axiosWithToken.post(
      `/api/${SERVICES.PLANNING_HISTORY}/image/${id}`,
      form,
      {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      }
    );
    return response?.data;
  } catch (e) {
    return null;
  }
};

export const ADMIN_OPTIONS = [
  {
    key: "requests",
    label: "Requests",
    leftIcon: "ion-calendar",
  },
  {
    key: "planning",
    label: "Planning",
    leftIcon: "ion-calendar",
  },
  {
    key: "clients",
    label: "Clients",
    leftIcon: "ion-bag",
  },
  {
    key: "locations",
    label: "Locations",
    leftIcon: "ion-ios-home",
  },
  {
    key: "subcontractors",
    label: "Subcontractors",
    leftIcon: "ion-briefcase",
  },
  {
    key: "agents",
    label: "Agents",
    leftIcon: "ion-ios-body",
  },
  {
    key: "logout",
    label: "Log out",
    leftIcon: "ion-log-out",
  },
];
export const SUBC_OPTIONS = [
  {
    key: "planning",
    label: "Planning",
    leftIcon: "ion-calendar",
  },
  {
    key: "agents",
    label: "Agents",
    leftIcon: "ion-ios-body",
  },
  {
    key: "logout",
    label: "Log out",
    leftIcon: "ion-log-out",
  },
];

export const CLIENT_OPTIONS = [
  {
    key: "requests",
    label: "Requests",
    leftIcon: "ion-calendar",
  },
  {
    key: "planning",
    label: "Planning",
    leftIcon: "ion-calendar",
  },
  {
    key: "branches",
    label: "Branches",
    leftIcon: "ion-calendar",
  },
  // {
  //   key: "locations",
  //   label: "Locations",
  //   leftIcon: "ion-ios-home",
  // },
  {
    key: "logout",
    label: "Log out",
    leftIcon: "ion-log-out",
  },
];

export const BRANCH_OPTIONS = [
  {
    key: "requests",
    label: "Requests",
    leftIcon: "ion-calendar",
  },
  {
    key: "planning",
    label: "Planning",
    leftIcon: "ion-calendar",
  },
  {
    key: "locations",
    label: "Locations",
    leftIcon: "ion-ios-home",
  },
  {
    key: "logout",
    label: "Log out",
    leftIcon: "ion-log-out",
  },
];

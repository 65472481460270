import React, { useEffect, useState } from "react";
import { TableViews } from "../../Tables/AntTables/AntTables";
import Modal from "../../../components/Feedback/Modal";
import { FormProvider, useForm } from "react-hook-form";
import SelectController from "../../../form/SelectController";
import { Planning } from "../../../redux/apis/types";
import { useDispatch, useSelector } from "react-redux";
import { State } from "../../../redux/root-reducer";
import {
  forwardPlanning,
  getRelatedPlanning,
} from "../../../redux/apis/planning";
import RadioGroupController from "../../../form/RadioGroupController";
import { get, SERVICES } from "../../../redux/apis/global";
import actions from "../../../redux/planning/actions";
import { Button } from "antd";

type Props = {
  visible: boolean;
  onClose: () => void;
};

type DataForm = {
  planning_id: number;
  subcontractor_id: number | null;
};

const ForwardPlanningModal = ({ visible, onClose }: Props) => {
  const [isLoading, setLoading] = useState(false);
  const dispatch = useDispatch();
  const [relatedPlanning, setRelatedPlanning] = useState([]);
  const { contractors } = useSelector((state: State) => state.Contractors);
  const { planningDetails } = useSelector((state: State) => state.Planning);
  useEffect(() => {
    getData();
    async function getData() {
      const resp = await getRelatedPlanning(planningDetails.id);
      setRelatedPlanning(resp);
    }
  }, []);

  const methods = useForm({
    defaultValues: {
      slots: [] as Array<DataForm>,
      slot: {
        subcontractor_id: planningDetails.subcontractor_id,
        planning_id: planningDetails.id,
      } as DataForm,
      remove_shifts: true,
    },
  });
  const {
    handleSubmit,
    formState: { errors },
    reset,
    getValues,
    watch,
  } = methods;

  useEffect(() => {
    if (relatedPlanning) {
      reset({
        ...getValues(),
        slots: relatedPlanning.map((item: Planning) => {
          return { planning_id: item.id, contractor_id: item.subcontractor_id };
        }),
      });
    }
  }, [relatedPlanning]);

  const relatedColumns = [
    {
      title: "Start Date",
      dataIndex: "start",
    },
    {
      title: "End Date",
      dataIndex: "end",
    },
    {
      title: "Subcontractor",
      render: (text: string, record: any, index: number) => (
        <SelectController
          rules={{ required: false }}
          name={`slots.${index}.subcontractor_id`}
          label={undefined}
          options={contractors}
          placeholder="subcontractor"
          optionKey="id"
          optionValue="name"
        />
      ),
    },
  ];

  const requiredColumns = [
    {
      title: "Start Date",
      dataIndex: "start",
    },
    {
      title: "End Date",
      dataIndex: "end",
    },
    {
      title: "Subcontractor",
      render: (text: string, record: any, index: number) => (
        <SelectController
          rules={{ required: true }}
          name={`slot.subcontractor_id`}
          error={errors?.slot && errors?.slot?.subcontractor_id}
          label={undefined}
          options={contractors}
          optionKey="id"
          optionValue="name"
          placeholder="subcontractor"
        />
      ),
    },
  ];

  const onSave = async (data: {
    slot: DataForm;
    slots: Array<DataForm>;
    remove_shifts: boolean;
  }) => {
    console.log(data);

    setLoading(true);
    const resp = await forwardPlanning({
      forwarded: [
        data.slot,
        ...data.slots.filter((slot: DataForm) => !!slot.subcontractor_id),
      ],
      remove_shifts: data.remove_shifts,
    });
    const planning = await get(SERVICES.PLANNING, planningDetails.id);
    if (planning) dispatch(actions.updatePlanning(planning));
    if (resp) {
      onClose();
    }

    setLoading(false);
  };

  const contractorId = watch("slot.subcontractor_id");
  return (
    <Modal
      title={"Forward Planning"}
      visible={visible}
      onOk={handleSubmit(onSave)}
      onCancel={onClose}
      confirmLoading={isLoading}
      okText="Forward"
      cancelText="Cancel"
      footer={[
        <Button key="back" onClick={onClose}>
          Cancel
        </Button>,
        <Button
          key="submit"
          disabled={!methods.formState.isDirty}
          type="primary"
          loading={isLoading}
          onClick={handleSubmit(onSave)}
        >
          Submit
        </Button>,
      ]}
    >
      <FormProvider {...methods}>
        <section style={{ marginBottom: 15 }}>
          <TableViews.SimpleView
            dataSource={[planningDetails]}
            columns={requiredColumns}
          />
        </section>
        {planningDetails?.subcontractor_id &&
          contractorId !== planningDetails.subcontractor_id &&
          planningDetails.shifts.length > 0 && (
            <div>
              <section>
                <RadioGroupController
                  label="Do you want to remove all the existing shift(s) related to the
                  previous Subcontractor? , if NO, only agents will be removed from the existing shift(s)"
                  options={[
                    { label: "NO", value: false },
                    { label: "YES", value: true },
                  ]}
                  optionKey="value"
                  optionValue="label"
                  name={`remove_shifts`}
                />
              </section>
            </div>
          )}

        {relatedPlanning && relatedPlanning.length > 0 && (
          <>
            <h4>
              You have {relatedPlanning.length} more request(s) to forward with
              the same planning type <b>{planningDetails.request_type}</b> in{" "}
              <b>
                {planningDetails.location
                  ? planningDetails.location.name
                  : "Location deleted"}
              </b>
            </h4>

            <section style={{ marginTop: 15 }}>
              <TableViews.SimpleView
                dataSource={relatedPlanning}
                columns={relatedColumns}
              />
            </section>
          </>
        )}
      </FormProvider>
    </Modal>
  );
};

export default ForwardPlanningModal;

import React, { createRef, lazy, Suspense, useEffect } from "react";
import {
  Route,
  Redirect,
  // BrowserRouter as Router,
  // HashRouter as Router,
  Switch,
} from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import ErrorBoundary from "./ErrorBoundary";
import { PUBLIC_ROUTE } from "./route.constants";
import Loader from "@iso/components/utility/loader";
import { ConnectedRouter } from "connected-react-router";
import { history } from "./redux/store";
import ConfirmAccount from "./containers/Profile/ConfirmAccount";
import PlanningDetailsModal from "./containers/client/PlanningDetails/PlanningDetailsModal";
import actions from "./redux/planning/actions";
import ChatWidget from "./containers/admin/chat/ChatWidget";
import { isClient } from "./constants";
import { createMuiTheme, MuiThemeProvider } from "@material-ui/core/styles";
import useWindowHook from "./useWindowHook";
import { chatActions } from "./redux/chat/actions";

const theme = createMuiTheme({
  overrides: {
    MuiAutocomplete: {
      popup: { zIndex: 1300 },
    },
    MuiOutlinedInput: {
      root: {
        position: "relative",
        "& $notchedOutline": {
          borderColor: "#1f2431",
        },
        "&:hover:not($disabled):not($focused):not($error) $notchedOutline": {
          borderColor: "#1f2431",
          // Reset on touch devices, it doesn't add specificity
          "@media (hover: none)": {
            borderColor: "rgba(0, 0, 0, 0.23)",
          },
        },
        "&$focused $notchedOutline": {
          borderColor: "#1f2431",
          borderWidth: 1,
        },
      },
    },
    MuiFormLabel: {
      root: {
        "&$focused": {
          color: "#1f2431",
        },
      },
    },
    MuiInputLabel: {
      root: {
        color: "rgba(0, 0, 0, 0.5)",
      },
    },
    MuiInputBase: {
      //input: {
      "&Mui-disabled": {
        color: "red",
      },
      //}
    },
    MuiTypography: {
      typography: {
        color: "#1f2431",
      },
    },
    MuiListItemText: {},
    MuiTab: {
      wrapper: {
        fontSize: "1rem",
      },
    },
    MuiButton: {
      root: {
        backgroundColor: "#1f2431",
      },
    },
  },
  palette: {
    primary: {
      main: "#1f2431",
    },
  },
  typography: {
    color: "#000",
  },
});

const Dashboard = lazy(() => import("./containers/Dashboard/Dashboard"));

const publicRoutes = [
  {
    path: PUBLIC_ROUTE.LANDING,
    exact: true,
    // component: lazy(() => import("@iso/containers/Pages/500/500")),
    component: lazy(() => import("@iso/containers/Pages/SignIn/SignIn")),
  },
  {
    path: PUBLIC_ROUTE.AUTH,
    exact: true,
    component: lazy(() => import("@iso/containers/Pages/SignIn/AuthCallback")),
  },
  {
    path: PUBLIC_ROUTE.REFRESHING,
    exact: true,
    component: lazy(() => import("@iso/containers/Pages/SplashScreen")),
  },
  {
    path: PUBLIC_ROUTE.PAGE_404,
    component: lazy(() => import("@iso/containers/Pages/404/404")),
  },
  {
    path: PUBLIC_ROUTE.PAGE_500,
    component: lazy(() => import("@iso/containers/Pages/500/500")),
  },
  {
    path: PUBLIC_ROUTE.SIGN_IN,
    component: lazy(() => import("@iso/containers/Pages/SignIn/SignIn")),
  },
  {
    path: PUBLIC_ROUTE.SIGN_UP,
    component: lazy(() => import("@iso/containers/Pages/SignUp/SignUp")),
  },
  {
    path: PUBLIC_ROUTE.FORGET_PASSWORD,
    component: lazy(() =>
      import("@iso/containers/Pages/ForgotPassword/ForgotPassword")
    ),
  },
  {
    path: PUBLIC_ROUTE.RESET_PASSWORD,
    component: lazy(() =>
      import("@iso/containers/Pages/ResetPassword/ResetPassword")
    ),
  },
  {
    path: PUBLIC_ROUTE.AUTH0_CALLBACK,
    component: lazy(() =>
      import("@iso/containers/Authentication/Auth0/Auth0Callback")
    ),
  },
];
function PrivateRoute({ children, ...rest }) {
  const { idToken, refreshing, user } = useSelector((state) => state.Auth);

  console.log("user : ", user);
  console.log("refreshing : ", refreshing);
  console.log("idToken : ", !!idToken);
  return (
    <Route
      {...rest}
      render={({ location }) =>
        idToken || refreshing ? (
          children
        ) : idToken && user?.verified === 1 ? (
          <Redirect
            to={{
              pathname: "/dashboard/confirm-account",
              state: { from: location },
            }}
          />
        ) : (
          <Redirect
            to={{
              pathname: "/signin",
              state: { from: location },
            }}
          />
        )
      }
    />
  );
}

export const audioRef = createRef();
export const audioNotifRef = createRef();
export const audioAlarmRef = createRef();

export default function Routes() {
  const { windowIsActive } = useWindowHook();
  const { selectedPlanning } = useSelector((state) => state.Planning);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(chatActions.toggleWindowActive(windowIsActive));
  }, [windowIsActive]);

  return (
    <ErrorBoundary>
      <Suspense fallback={<Loader />}>
        {/* <Router> */}
        <ConnectedRouter history={history}>
          <Switch>
            {publicRoutes.map((route, index) => (
              <Route key={index} path={route.path} exact={route.exact}>
                <route.component />
              </Route>
            ))}

            <Route key="confirmation" path="/confirm-account">
              <ConfirmAccount />
            </Route>

            <PrivateRoute path="/dashboard">
              <Dashboard />
              {!!selectedPlanning && (
                <PlanningDetailsModal
                  modalVisible={!!selectedPlanning}
                  onClose={() => dispatch(actions.setSelectedPlanning(null))}
                  planningId={selectedPlanning.id}
                />
              )}
            </PrivateRoute>
          </Switch>
          {!isClient && (
            <MuiThemeProvider theme={theme}>
              <ChatWidget />
            </MuiThemeProvider>
          )}
          <audio
            ref={audioRef}
            src={require("./assets/mp3/new_message.mp3")}
            style={{ display: "none" }}
          />
          <audio
            ref={audioNotifRef}
            src={require("./assets/mp3/request.mp3")}
            style={{ display: "none" }}
          />
          <audio
            ref={audioAlarmRef}
            src={require("./assets/mp3/alarm.mp3")}
            style={{ display: "none" }}
          />
        </ConnectedRouter>
        {/* </Router> */}
      </Suspense>
    </ErrorBoundary>
  );
}
